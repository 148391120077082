import { NumberInput, Select, TextInput } from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';
import { motion, useAnimation } from 'framer-motion';
import {
  useMemo, useEffect, useRef, memo,
  useCallback,
  useState,
} from 'react';
import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import { UniversalFieldProps } from './types';

// Function to parse validation string
function parseValidators(validation: string) {
  if (!validation) return [];

  return validation.split(',')?.map((rule) => {
    const [key, value] = rule.split('=');
    return { key, value: value || true };
  });
}

// ADT for validation rules
type Validator =
  | { type: 'required' }
  | { type: 'min'; value: number }
  | { type: 'max'; value: number }
  | { type: 'alpha'; value: number }
  | { type: 'alphanum'; value: number };

function createValidator({ key, value }: { key: string; value: any }): Validator {
  switch (key) {
    case 'required':
      return { type: 'required' };
    case 'min':
      return { type: 'min', value: Number(value) };
    case 'max':
      return { type: 'max', value: Number(value) };
    case 'alpha':
      return { type: 'alpha', value: Number(value) };
    case 'alphanum':
      return { type: 'alphanum', value: Number(value) };
    default:
      return { type: 'required' };
  }
}

const UniversalField = memo(({
  type,
  label,
  value: _value,
  size = 'sm',
  required = false,
  itemClassName,
  placeHolder,
  disabled,
  readOnly,
  shouldScroll = true,
  itemStyle,
  validation,
  onValueChange,
  setError: setError_,
  ...motionProps
}: UniversalFieldProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  const [errorMsg, setErrorMsg] = useState('');

  const value = useMemo(() => _value || '', [_value]);

  const dateTimeValue = useMemo(() => (
    value ? Dayjs(value).toDate() : undefined
  ), [value]);

  const textInputPlaceholder = useMemo(() => (
    placeHolder || value || 'No value extracted'
  ), [placeHolder, value]);

  const onTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange?.(e.target.value);
  }, [onValueChange]);

  const onNumberInputChange = useCallback((val: string | number) => {
    onValueChange?.(parseFloat(val?.toString() || '0'));
  }, [onValueChange]);

  const onDateTimeChange = useCallback((datetime: DateValue) => {
    onValueChange?.(datetime ? Dayjs(datetime).format() : null);
  }, [onValueChange]);

  const setError = useCallback((msg: string) => {
    setErrorMsg(msg);
    setError_?.(msg);
  }, [setError_, setErrorMsg]);

  useEffect(() => {
    const parsedValidators = parseValidators(validation);
    const validationRules = parsedValidators.map(createValidator);

    validationRules.every((rule) => {
      if (rule.type === 'required' && !value) {
        setError(`${label} is a required field and cannot be empty.`);
        return false;
      }

      if (
        rule.type === 'min'
        && (
          (type === 'input' && value.length < rule.value)
          || (type === 'number input' && Number(value) < rule.value)
        )
      ) {
        setError(`${label} must be at least ${rule.value}.`);
        return false;
      }

      if (
        rule.type === 'max'
        && (
          (type === 'input' && value.length > rule.value)
          || (type === 'number input' && Number(value) > rule.value)
        )
      ) {
        setError(`${label} must be at most ${rule.value}.`);
        return false;
      }

      if (
        rule.type === 'alpha'
        && !/^[a-zA-Z]+$/.test(value)
      ) {
        setError(`${label} must be alphabetic.`);
        return false;
      }

      if (
        rule.type === 'alphanum'
        && !/^[a-zA-Z0-9]+$/.test(value)
      ) {
        setError(`${label} must be alphanumeric.`);
        return false;
      }
      setError('');
      return true;
    });
  }, [label, required, setError, value, validation, type]);

  useEffect(() => {
    if (shouldScroll && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // Start the blinking animation
    controls.start({
      opacity: [1, 0, 1],
      transition: { duration: 0.3, repeat: 2 },
    });
  }, [controls, shouldScroll]); // Added shouldScroll to dependencies

  const item = useMemo(() => {
    if (type === 'dropdown') {
      return (
        <Select
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          onChange={onValueChange}
        />
      );
    }

    if (type === 'input') {
      return (
        <TextInput
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          error={errorMsg}
          onChange={onTextInputChange}
        />
      );
    }

    if (type === 'number input') {
      return (
        <NumberInput
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          error={errorMsg}
          hideControls
          onChange={onNumberInputChange}
        />
      );
    }

    if (type === 'datetime') {
      return (
        <DateTimePicker
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={dateTimeValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          onChange={onDateTimeChange}
          error={errorMsg}
        />
      );
    }

    return (
      <TextInput
        variant="unstyled"
        className={twMerge('w-full', itemClassName)}
        label={label}
        value={value}
        placeholder={textInputPlaceholder}
        disabled={disabled}
        size={size}
        readOnly
        style={itemStyle}
        error={errorMsg}
        styles={{
          input: {
            borderBottom: '1px solid #A1A3AB',
            borderRadius: '0',
          },
        }}
      />
    );
  }, [type, itemClassName, label, value, textInputPlaceholder, disabled,
    size, itemStyle, placeHolder, readOnly, onValueChange, onTextInputChange,
    onNumberInputChange, dateTimeValue, onDateTimeChange, errorMsg]);

  return (
    <motion.div
      ref={itemRef}
      animate={controls}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0, scale: 0, transition: { duration: 0.3 } }}
      {...motionProps}
    >
      {item}
    </motion.div>
  );
});

export default UniversalField;
